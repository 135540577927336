// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

import {
  assertProcessEnv,
  CommonConfig,
  GoogleSheetConfig,
  IntercomConfig,
  SentryConfig,
  UnleashConfig,
} from '@supy/common';
import { MixpanelConfig, MixpanelPortal } from '@supy/mixpanel';
import { OpenTelemetryConfig } from '@supy/opentelemetry';

assertProcessEnv();

export const environment: CommonConfig &
  GoogleSheetConfig &
  SentryConfig &
  MixpanelConfig &
  IntercomConfig &
  UnleashConfig &
  OpenTelemetryConfig = {
  mixpanel: {
    token: process.env.MIXPANEL_TOKEN,
    portal: process.env.MIXPANEL_PORTAL as MixpanelPortal,
    debug: JSON.parse(process.env.MIXPANEL_DEBUG) as boolean,
  },
  intercom: {
    appId: process.env.INTERCOM_APP_ID,
  },
  production: JSON.parse(process.env.PRODUCTION) as boolean,
  apiUrlPrefix: process.env.API_URL_PREFIX,
  apiUrl: process.env.API_URL,
  apiUrlBff: process.env.API_URL_BFF,
  google: {
    sheets: {
      apiKey: process.env.GOOGLE_SHEETS_API_KEY,
      clientId: process.env.GOOGLE_SHEETS_CLIENT_ID,
      discoveryDocs: process.env.GOOGLE_SHEETS_DISCOVERY_DOCS.split(','),
      scopes: process.env.GOOGLE_SHEETS_SCOPES,
    },
  },
  sentry: {
    dsn: process.env.SENTRY_DSN,
    sampleRate: +process.env.SENTRY_SAMPLE_RATE,
    environment: process.env.SENTRY_ENVIRONMENT,
    enabled: JSON.parse(process.env.SENTRY_ENABLED) as boolean,
  },
  opentelemetry: {
    application: {
      name: process.env.OPENTELEMETRY_APPLICATION_NAME,
      version: process.env.OPENTELEMETRY_APPLICATION_VERSION,
    },
    tracing: {
      enabled: JSON.parse(process.env.OPENTELEMETRY_TRACING_ENABLED) as boolean,
      propagate: process.env.OPENTELEMETRY_TRACING_PROPAGATE.split(','),
      whitelist: process.env.OPENTELEMETRY_TRACING_WHITELIST.split(','),
      url: process.env.OPENTELEMETRY_TRACING_URL,
    },
    metrics: {
      enabled: JSON.parse(process.env.OPENTELEMETRY_METRICS_ENABLED) as boolean,
      url: process.env.OPENTELEMETRY_METRICS_URL,
    },
  },
  unleash: {
    appName: process.env.UNLEASH_APP_NAME,
    clientKey: process.env.UNLEASH_PROXY_CLIENT_KEY,
    proxyUrl: process.env.UNLEASH_PROXY_URL,
  },
  versionHash: process.env.VERSION_HASH ?? 'local', // version hash is injected inside of Dockerfile to sync git commit hash
};

Object.freeze(environment);

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
